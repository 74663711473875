import { useAtom } from 'jotai';
import { useRouter } from 'next/router';
import { FunctionComponent, useEffect } from 'react';

import { selectLanguageOpen } from '../../../../state/select-language';
import Slider from '../../../ui/slider';

import useTranslation from 'next-translate/useTranslation';

import LanguageSelector from './options';

const SelectLanguageInDrawer: FunctionComponent<unknown> = () => {
  const { query } = useRouter();
  const { t } = useTranslation();
  const [open, setOpen] = useAtom(selectLanguageOpen);

  useEffect(() => {
    if (query.showSelectLanguage === 'true') {
      setOpen(true);
    }
  }, [query]);

  return (
    <>
      <div className={'hidden language-selector-footer'}>
        <LanguageSelector />
      </div>
      <Slider
        isOpen={open}
        onClose={() => setOpen(false)}
        enterFrom="right"
        isFullscreenMobile={true}
      >
        <LanguageSelector />
      </Slider>
    </>
  );
};
export default SelectLanguageInDrawer;
