import { originalRoutes } from '../../../hooks/use-link-props';

const correlationTypes = {
  [originalRoutes['sub-categories']]: [
    {
      original: 'category',
      target: 'category',
    },
  ],
  [originalRoutes['brands-with-part']]: [
    {
      original: 'category',
      target: 'category',
    },
    {
      original: 'subCategory',
      target: 'part',
    },
  ],
  [originalRoutes['models-with-part']]: [
    {
      original: 'category',
      target: 'category',
    },
    {
      original: 'subCategory',
      target: 'part',
    },
  ],
  [originalRoutes['versions-with-part']]: [
    {
      original: 'category',
      target: 'category',
    },
    {
      original: 'subCategory',
      target: 'part',
    },
  ],
  [originalRoutes['sub-categories-with-vehicle']]: [
    {
      original: 'slug',
      target: 'category',
    },
  ],
  [originalRoutes['products']]: [
    {
      original: 'category',
      target: 'category',
    },
    {
      original: 'subCategory',
      target: 'part',
    },
  ],
  [originalRoutes['reference']]: [
    {
      original: 'category',
      target: 'category',
    },
    {
      original: 'subCategory',
      target: 'part',
    },
  ],
  [originalRoutes['faqs_group']]: [{ original: 'group', target: 'faqgroup' }],

  [originalRoutes.product]: [
    {
      original: 'category',
      target: 'category',
    },
    {
      original: 'subCategory',
      target: 'product',
    },
  ],

  [originalRoutes['advanced-search-products-search']]: [
    {
      original: 'category',
      target: 'category',
    },
    {
      original: 'subCategory',
      target: 'part',
    },
  ],
  [originalRoutes['advanced-search-product']]: [
    {
      original: 'category',
      target: 'category',
    },
    {
      original: 'subCategory',
      target: 'part',
    },
    {
      original: 'prodCategory',
      target: 'category',
    },
    {
      original: 'prodSlug',
      target: 'product',
    },
  ],
} as Record<
  string,
  Array<{ original: string; target: 'category' | 'part' | 'product' }>
>;

export default correlationTypes;
