import { IncomingMessage } from 'http';

import Cookies from 'js-cookie';

import { fechFromBPartsApi } from '../utils';

export const translateSlugs = (
  {
    lang_origin,
    lang_trans,
    slugs,
    csrftoken,
  }: {
    lang_origin: string;
    lang_trans: string | null;
    slugs: Array<{
      slug_type: 'category' | 'part' | 'product';
      slug_origin: string;
    }>;
    csrftoken?: string;
  },
  req?: IncomingMessage
): Promise<
  | {
      lang_origin: string;
      lang_trans: string;
      slugs: Array<{
        slug_type: 'category' | 'part' | 'product';
        slug_origin: string;
      }>;
    }
  | { value: string }
> =>
  fechFromBPartsApi(`/${lang_origin}/shop/slugs_trans`, {
    method: 'POST',
    credentials: 'include',
    headers: new Headers({
      ...(!!req ? (req?.headers as HeadersInit) : {}),
      'Content-Type': 'application/json',
      cookie: '',
      'X-CSRFToken': csrftoken ?? Cookies.get('csrftoken') ?? '',
    } as HeadersInit),
    body: JSON.stringify({ lang_origin, lang_trans, slugs }),
  }).then(async (value) => {
    const json = await value.json();

    if (value.status >= 400 && value.status < 600) {
      throw json;
    }

    return json;
  });
