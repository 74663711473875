const messagesDA = require('../locales/da/routes.json');
const messagesDE = require('../locales/de/routes.json');
const messagesEN = require('../locales/en/routes.json');
const messagesES = require('../locales/es/routes.json');
const messagesFR = require('../locales/fr/routes.json');
const messagesIT = require('../locales/it/routes.json');
const messagesNB = require('../locales/nb/routes.json');
const messagesNL = require('../locales/nl/routes.json');
const messagesPT = require('../locales/pt/routes.json');

const { locales: nextLocales } = require('./i18n');

const locales = nextLocales.filter((locale) => locale !== 'en');

const messages = {
  da: messagesDA,
  de: messagesDE,
  es: messagesES,
  fr: messagesFR,
  it: messagesIT,
  nb: messagesNB,
  pt: messagesPT,
  nl: messagesNL,
};

const staticPages = [
  'faqs',
  'login',
  'password-reset',
  'password-reset-confirm',
  'contacts',
  'legal-mentions',
  'terms-and-conditions',
  'about-us',
  'signup',
  'nps',
  'unsubscribe-emails',
  'create-part-alert',
];

// route translation base, entry route
const clientPages = [
  { route: 'checkout', entry: 'checkout' },
  { route: 'private-area', entry: 'private-area' },
  { route: 'advanced-search', entry: 'advanced-search' },
];

const toReplace = {
  'auto-parts': {
    pt: 'pecas-auto',
    it: 'ricambi-auto',
    de: 'autoteile',
    fr: 'pieces-automobiles',
    es: 'recambios-auto',
    nb: 'bildeler',
    da: 'autodele',
    nl: 'auto-onderdelen',
  },
  category: {
    pt: 'categoria',
    it: 'categoria',
    de: 'kategorie',
    fr: 'categorie',
    es: 'categoria',
    nb: 'kategori',
    da: 'kategori',
    nl: 'categorie',
  },
  categories: {
    pt: 'categorias',
    it: 'categorie',
    de: 'kategorien',
    fr: 'categories',
    es: 'categorias',
    nb: 'kategorier',
    da: 'kategorier',
    nl: 'categorieen',
  },
  brands: {
    pt: 'marcas',
    it: 'marche',
    de: 'marken',
    fr: 'marques',
    es: 'marcas',
    nb: 'merker',
    da: 'marker',
    nl: 'merken',
  },
  models: {
    pt: 'modelos',
    it: 'modelli',
    de: 'modelle',
    fr: 'modeles',
    es: 'modelos',
    nb: 'modeller',
    da: 'modeller',
    nl: 'modellen',
  },
  versions: {
    pt: 'versoes',
    it: 'versioni',
    de: 'versionen',
    fr: 'versions',
    es: 'versiones',
    nb: 'versjoner',
    da: 'versioner',
    nl: 'versies',
  },
  vehicle: {
    pt: 'veiculo',
    it: 'vetture',
    de: 'fahrzeuge',
    fr: 'voiture',
    es: 'coche',
    nb: 'kjoretoy',
    da: 'koretojer',
    nl: 'voertuig',
  },
};

const replaceTranslations = (message, locale) => {
  Object.keys(toReplace).forEach((key) => {
    const folderString = `\/(${toReplace[key][locale]})(\/|$)`;
    const folder = new RegExp(folderString, 'g');

    message = message.replace(folder, `/${key}/`);
  });

  return message.replace(/\[/g, ':').replace(/\]/g, '');
};

module.exports = async () => {
  const routes = new Set();

  locales.forEach((locale) => {
    const keys = Object.keys(messages[locale]).filter(
      (k) => !staticPages.includes(k)
    );

    keys.forEach((key) => {
      routes.add({
        source: messages[locale][key].replace(/\[/g, ':').replace(/\]/g, ''),
        destination: replaceTranslations(messages[locale][key], locale),
        locale: false,
      });
    });

    staticPages.forEach((key) => {
      routes.add({
        source: messages[locale][key],
        destination: `/${locale}/${key}`,
        locale: false,
      });
    });

    clientPages.forEach((config) => {
      Object.entries(messages[locale]).forEach(([key, route]) => {
        if (key.startsWith(config.route)) {
          routes.add({
            source: route,
            destination: `/${locale}/${config.entry}`,
            locale: false,
          });
        }
      });
    });
  });

  routes.add({
    source: '/file/:path*',
    destination: 'https://bparts-eu.s3.amazonaws.com:443/:path*',
  });

  routes.add({
    source: '/:locale/certificate-unavailability',
    destination: process.env.API_HOST_SHOP + '/:locale/shop/products/no-stock',
    locale: false,
  });

  // en client routes
  clientPages.forEach((config) => {
    Object.entries(messagesEN).forEach(([key, route]) => {
      if (key.startsWith(config.route)) {
        routes.add({
          source: route,
          destination: `/${config.entry}`,
        });
      }
    });
  });

  routes.add({
    source: `/api/:locale(${nextLocales.join('|')})/:path*`,
    destination: process.env.API_HOST_SHOP + '/:locale/:path*',
  });

  return Array.from(routes);
};
