import { fechFromBPartsApi } from '../utils';

export const setLanguage = ({
  locale,
  language,
  url_path,
  csrftoken,
}: {
  locale: string;
  language: string;
  url_path?: string;
  csrftoken: string;
}): Promise<{ old_url_path: string | null } | { value: string }> =>
  fechFromBPartsApi(`/${locale}/shop/languages/change`, {
    method: 'PUT',
    headers: new Headers([
      ['Content-Type', 'application/json'],
      ['X-CSRFToken', csrftoken],
    ]),
    credentials: 'include',
    body: JSON.stringify({ value: language, ...(url_path && { url_path }) }),
  }).then(async (value) => {
    const json = await value.json();

    if (value.status >= 400 && value.status < 600) {
      throw json;
    }

    return json;
  });
