import cx from 'classnames';
import { ComponentProps, forwardRef } from 'react';

import classes from './featured.module.css';

const variants: { [key: string]: string } = {
  default: cx('font-bold', 'text-brand-100'),
  neutral: '',
};

const FeaturedLink = forwardRef<
  HTMLAnchorElement,
  {
    as?: 'a' | 'button';
    variant?: 'default' | 'neutral' | 'black' | 'white' | 'blank';
  } & ComponentProps<'a'>
>(
  (
    {
      as: Component = 'a',
      variant = 'default',
      className,
      disableFocusOnMenu,
      ...props
    },
    ref
  ) => (
    <Component
      ref={ref}
      className={cx(
        [classes.featured],
        { [classes.defaultLine]: variant === 'default' },
        { [classes.transparentLine]: variant === 'neutral' },
        { [classes.blackLine]: variant === 'black' },
        { [classes.whiteLine]: variant === 'white' },
        variants[variant],
        'cursor-pointer',
        { 'pb-1': variant !== 'blank' },
        'relative',
        'focus:outline-none',
        'focus:bg-brand-10',
        className
      )}
      {...props}
    />
  )
);

FeaturedLink.displayName = 'FeaturedLink';

export default FeaturedLink;
