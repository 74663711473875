import { IncomingMessage } from 'http';

import { QueryFunctionContext, QueryKey } from '@tanstack/react-query';

import { translateSlugs } from '../../../services/language/slug_translation';
import { getTranslateSlugsKeys } from '../query-keys';

const getTranslatedSlugs = (
  values: {
    csrftoken?: string;
    lang_origin: string;
    lang_trans: null;
    slugs: Array<{
      slug_type: 'category' | 'part' | 'product';
      slug_origin: string;
    }>;
  },
  req?: IncomingMessage
): [
  QueryKey,
  (values: QueryFunctionContext) => ReturnType<typeof translateSlugs>
] => [
  getTranslateSlugsKeys(values),
  ({ queryKey: [, locale, slugs] }) => {
    return translateSlugs(
      {
        lang_origin: locale as string,
        lang_trans: null,
        slugs: slugs as Array<{
          slug_type: 'category' | 'part' | 'product';
          slug_origin: string;
        }>,
      },
      req
    );
  },
];

export default getTranslatedSlugs;
