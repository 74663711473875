import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useAtom } from 'jotai';
import Cookies from 'js-cookie';
import { resolveHref } from 'next/dist/client/resolve-href';
import { useRouter } from 'next/router';
import getT from 'next-translate/getT';
import useTranslation from 'next-translate/useTranslation';
import { useMemo, useRef, useState } from 'react';

import { originalRoutes } from '../../../hooks/use-link-props';
import { fetchLanguages } from '../../../services/language/list';
import { setLanguage } from '../../../services/language/set';
import { translateSlugs } from '../../../services/language/slug_translation';
import { openNavigationToggle } from '../../../state/navigation';
import { useAppContext } from '../../providers/app-context';
import BPImage from '../../ui/image';
import Select from '../../ui/inputs/selectv2';
import ItemWithImage from '../../ui/inputs/selectv2/item-with-image';

// correlation needed when pathanme keys doesn't match
// the api available keys
// { "route from originalRoutes": [{
//    original: "key from originalRoutes",
//    target: "key to use in api"
//  }]
// }

const correlationTypes = {
  [originalRoutes['sub-categories']]: [
    {
      original: 'category',
      target: 'category',
    },
  ],
  [originalRoutes['brands-with-part']]: [
    {
      original: 'category',
      target: 'category',
    },
    {
      original: 'subCategory',
      target: 'part',
    },
  ],
  [originalRoutes['models-with-part']]: [
    {
      original: 'category',
      target: 'category',
    },
    {
      original: 'subCategory',
      target: 'part',
    },
  ],
  [originalRoutes['versions-with-part']]: [
    {
      original: 'category',
      target: 'category',
    },
    {
      original: 'subCategory',
      target: 'part',
    },
  ],
  [originalRoutes['sub-categories-with-vehicle']]: [
    {
      original: 'slug',
      target: 'category',
    },
  ],
  [originalRoutes['products']]: [
    {
      original: 'category',
      target: 'category',
    },
    {
      original: 'subCategory',
      target: 'part',
    },
  ],
  [originalRoutes['reference']]: [
    {
      original: 'category',
      target: 'category',
    },
    {
      original: 'subCategory',
      target: 'part',
    },
  ],
  [originalRoutes['faqs_group']]: [{ original: 'group', target: 'faqgroup' }],

  [originalRoutes.product]: [
    {
      original: 'category',
      target: 'category',
    },
    {
      original: 'subCategory',
      target: 'product',
    },
  ],

  [originalRoutes['advanced-search-products-search']]: [
    {
      original: 'category',
      target: 'category',
    },
    {
      original: 'subCategory',
      target: 'part',
    },
  ],
  [originalRoutes['advanced-search-product']]: [
    {
      original: 'category',
      target: 'category',
    },
    {
      original: 'subCategory',
      target: 'part',
    },
    {
      original: 'prodCategory',
      target: 'category',
    },
    {
      original: 'prodSlug',
      target: 'product',
    },
  ],
};

export const loadLanguages = async ({
  queryKey: [_, locale, locales, isBparts],
}: {
  queryKey: any[];
}) => {
  const data = await fetchLanguages({ locale });

  const filteredData = isBparts
    ? data
    : data.filter((lng) => {
        return locales.indexOf(lng.initials) !== -1;
      });

  const dataWithIcon = filteredData.map((e) => ({
    icon: `${
      process.env.PUBLIC_PREFIX
    }/svg/icons/flags/${e.initials.toLowerCase()}.svg`,
    value: e.initials,
    label: e.language,
    selected: e.selected,
  }));

  return dataWithIcon;
};

const LanguageSelector = () => {
  const router = useRouter();
  const { locale = 'en', asPath, query, push, pathname, locales } = router;
  const { t } = useTranslation();
  const languageRef = useRef();
  const { isBparts } = useAppContext();
  const [, toggleMenu] = useAtom(openNavigationToggle);
  const [error, setError] = useState<Array<string>>([]);

  const { data: languages, isLoading: isLoadingLanguages } = useQuery(
    ['languages-list', locale, locales, isBparts],
    loadLanguages
  );

  const { mutate, isLoading: isMutating } = useMutation(
    async ({ locale, language }: { locale: string; language: string }) => {
      const csrftoken = Cookies.get('csrftoken') || '';

      let url_path = '';
      let slugsToTranslate = null;

      // verify if the target language is available in this website
      if (!locales.includes(language)) {
        // if language is not available, the url_path will be used
        // in setLanguage API call to get the link to this page
        // in the old website
        url_path = asPath;
      } else {
        // get slugs that need translation to new language
        slugsToTranslate = [];
        correlationTypes[pathname]?.map((q) => {
          if (query[q.original]) {
            slugsToTranslate.push({
              slug_type: q.target,
              slug_origin: query[q.original],
            });
          }
        });
      }

      const transSlugs = url_path
        ? null
        : await translateSlugs({
            lang_origin: locale,
            lang_trans: language,
            slugs: slugsToTranslate,
            csrftoken,
          });

      const languageSet = await setLanguage({
        locale,
        language,
        ...(url_path && { url_path }), // <- if we need old website url
        csrftoken,
      });

      return [languageSet, transSlugs];
    },
    {
      onSuccess: async (data, variables) => {
        if (data[0]?.old_url_path) {
          // if the setLanguage fetch return an old_url_path we need to redirect
          // because it is only returned by the API when we send the url_path
          window.location = data[0]?.old_url_path;
        } else {
          // get the current route key of routes json, from pathname
          const route_key = Object.keys(originalRoutes).find(
            (k) => originalRoutes[k] === pathname
          );

          // get translated slugs
          // part need to be hacked, remember? part === subCategory
          let translatedSlugs = {};
          correlationTypes[pathname]?.map((q) => {
            const slug = data[1]['slugs'].find((s) => s.slug_type === q.target);
            translatedSlugs = {
              ...translatedSlugs,
              [q.original]: slug ? slug.slug_trans : undefined,
            };
          });

          const newQuery = {
            ...query, // <- keep all parameters
            ...translatedSlugs, // <- add translated slugs
          };
          
          // setup a new t function for new language
          const t2 = await getT(variables.language, 'routes');

          // this is almost a copycat of what exists in the useLinkProps()
          push(
            // href:
            {
              pathname,
              query: newQuery,
            },
            // as:
            resolveHref(
              router,
              {
                pathname: t2(route_key), // force translate to new language
                query: newQuery,
              },
              true
            )[1],
            { locale: variables.language }
          );
          toggleMenu();
        }
      },
      onError: (error) => {
        const errorMessages: Array<string> = [];

        Object.keys(error).map((key) =>
          errorMessages.push(`${key}: ${error[key]}`)
        );

        setError(errorMessages);
      },
    }
  );

  const language = useMemo(() => {
    if (languages) {
      const currentCurrency = languages.find((l) => l.selected);
      return currentCurrency;
    }

    return undefined;
  }, [languages]);

  return (
    <>
      <Select
        value={language?.value}
        disabled={isLoadingLanguages || isMutating}
        onChange={(value: string) => mutate({ locale, language: value })}
        element={language?.label}
        options={languages?.map((item) => ({
          id: item.value.toString(),
          value: item.value,
          element: item.label,
        }))}
        label={t('header:links.language')}
        menuPlacement="top"
      />
    </>
  );
};

export default LanguageSelector;
