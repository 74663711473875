import { useQuery } from '@tanstack/react-query';
import cx from 'classnames';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';

import { loadLanguages } from '..';
import getHreflangGetLink from '../../../../utils/generate-translated-link';
import { originalRoutes } from '../../../../hooks/use-link-props';
import getTranslatedSlugs from '../../../../utils/react-query/query-options/get-translated-slugs';
import { useAppContext } from '../../../providers/app-context';
import { getVariant } from '../../../ui/button';
import BPImage from '../../../ui/image';
import FeaturedLink from '../../../ui/links/featured';
import correlationTypes from '../correlationTypes';

const LanguageSelector = () => {
  const router = useRouter();
  const { locale = 'en', query, pathname, locales, route } = router;
  const { isBparts } = useAppContext();
  const { t } = useTranslation();

  const { data: allLanguages, isLoading: isLoadingLanguages } = useQuery(
    ['languages-list', locale, locales, isBparts],
    loadLanguages
  );

  const slugsToTranslate = [] as Parameters<
    typeof getTranslatedSlugs
  >[0]['slugs'];

  correlationTypes[pathname]?.map((q) => {
    if (query[q.original]) {
      slugsToTranslate.push({
        slug_type: q.target,
        slug_origin: query[q.original] as string,
      });
    }
  });

  const { data: tranlatedSlugs } = useQuery(
    ...getTranslatedSlugs({
      lang_origin: locale,
      lang_trans: null,
      slugs: slugsToTranslate,
    })
  );

  const routeToHref = Object.keys(originalRoutes).find(
    (k: keyof typeof originalRoutes) => originalRoutes[k] === route
  ) as keyof typeof originalRoutes;

  return (
    <>
      <div className="flex gap-4 w-full h-12 px-4 font-bold items-center">
        <BPImage
          src={`${process.env.PUBLIC_PREFIX}/svg/icons/flag.svg`}
          width={16}
          height={16}
          className={cx('h-4', 'w-4')}
        />
        <span>{t('common:userSettings.language')}</span>
      </div>
      <ul className="h-full overflow-y-auto flex flex-col">
        {allLanguages
          ?.filter((l) => locales?.includes(l.value))
          ?.map((language, index) => (
            <li
              key={language.value}
              className="w-full h-12 flex flex-row items-center justify-center"
            >
              <FeaturedLink
                variant={'blank'}
                key={language.value}
                hrefLang={language.value}
                href={getHreflangGetLink({
                  nextRouter: router,
                  locale: language.value,
                  params: query,
                  route: routeToHref,
                  // @ts-ignore
                  translatedSlugs: tranlatedSlugs?.slugs,
                })}
                className={cx(
                  getVariant('GHOST-PRIMARY'),
                  'px-4 w-full h-12 text-neutral-80 flex flex-row items-center gap-4',
                  language.selected ? 'font-bold' : 'font-normal'
                )}
              >
                {language.selected ? (
                  <BPImage
                    src={`${process.env.PUBLIC_PREFIX}/svg/icons/check.svg`}
                    width={16}
                    height={16}
                    className={cx('h-4', 'w-4')}
                  />
                ) : (
                  <div className="w-4"></div>
                )}
                {language.label}
              </FeaturedLink>
            </li>
          ))}
      </ul>
    </>
  );
};

export default LanguageSelector;
