import { ParsedUrlQuery } from 'querystring';

import { resolveHref } from 'next/dist/client/resolve-href';
import { useRouter } from 'next/router';

import routesDA from '../../locales/da/routes.json';
import routesDE from '../../locales/de/routes.json';
import routesEN from '../../locales/en/routes.json';
import routesES from '../../locales/es/routes.json';
import routesFR from '../../locales/fr/routes.json';
import routesIT from '../../locales/it/routes.json';
import routesNB from '../../locales/nb/routes.json';
import routesNL from '../../locales/nl/routes.json';
import routesPT from '../../locales/pt/routes.json';
import { originalRoutes } from '../hooks/use-link-props';

const replaceParams = (
  nextRouter: ReturnType<typeof useRouter>,
  route: string,
  params?: { [key: string]: string }
) => {
  const router = nextRouter;

  return resolveHref(
    router,
    {
      pathname: route,
      query: params,
    },
    true
  )[1] as string;
};

const removeTrailingSlash = (route: string) => {
  if (route[route.length - 1] === '/') {
    return route.substring(0, route.length - 1);
  }

  return route;
};

type paramsProps = {
  locale: string;
  params?: ParsedUrlQuery;
  route: keyof typeof originalRoutes;
  translatedSlugs?: {
    da: { slug_type: string; slug_origin: string; slug_trans: string }[];
    de: { slug_type: string; slug_origin: string; slug_trans: string }[];
    en: { slug_type: string; slug_origin: string; slug_trans: string }[];
    es: { slug_type: string; slug_origin: string; slug_trans: string }[];
    fr: { slug_type: string; slug_origin: string; slug_trans: string }[];
    it: { slug_type: string; slug_origin: string; slug_trans: string }[];
    nb: { slug_type: string; slug_origin: string; slug_trans: string }[];
    nl: { slug_type: string; slug_origin: string; slug_trans: string }[];
    pt: { slug_type: string; slug_origin: string; slug_trans: string }[];
  };
  nextRouter: ReturnType<typeof useRouter>;
};

const getHreflangGetLink = ({
  locale,
  params,
  route,
  translatedSlugs,
  nextRouter,
}: paramsProps) => {
  const routes = {
    da: routesDA[route],
    de: routesDE[route],
    en: routesEN[route],
    es: routesES[route],
    fr: routesFR[route],
    it: routesIT[route],
    nb: routesNB[route],
    nl: routesNL[route],
    pt: routesPT[route],
  };
  const localefinal = locale === 'en-gb' ? 'en' : locale;

  const finalLink = removeTrailingSlash(
    replaceParams(
      nextRouter,
      // @ts-ignore
      routes[localefinal],
      params
        ? Object.entries(params).reduce((final, [query, slug]) => {
            if (
              translatedSlugs &&
              // @ts-ignore
              translatedSlugs[localefinal] &&
              // @ts-ignore
              translatedSlugs[localefinal].find((e) => e.slug_origin === slug)
            ) {
              // @ts-ignore
              const newSlug = translatedSlugs[localefinal].find(
                (e) => e.slug_origin === slug
              );

              return { ...final, [query]: newSlug?.slug_trans ?? slug };
            }
            return { ...final, [query]: slug };
          }, {})
        : {}
    )
  );

  if ((locale === 'en-gb' || locale === 'en') && route === 'index') {
    return `${process.env.NEXT_PUBLIC_HOST}/en${
      //we will only need this validation for local environment
      process.env.NEXT_PUBLIC_HOST?.includes('localhost') ? '?rld_local' : ''
    }`;
  }

  return `${process.env.NEXT_PUBLIC_HOST}${finalLink}`;
};

export default getHreflangGetLink;
