import getConfig from 'next/config';

import { fechFromBPartsApi } from '../utils';

const { serverRuntimeConfig } = getConfig();

export const fetchLanguages = ({
  locale,
}: {
  locale: string;
}): Promise<Languages> =>
  fechFromBPartsApi(`/${locale}/shop/languages`, {
    credentials: 'include',
  }).then((value) => value.json());
