
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
// TODO: THIS LINE SHOULD BE REMOVED AFTER TESTING IE11
// import '../polyfills/intl-generic';

import {
  QueryClient,
  Hydrate,
  QueryClientProvider,
} from '@tanstack/react-query';
import cx from 'classnames';
import { Provider, useAtom } from 'jotai';
import { AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { memo, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import FooterUpperCurve from '../components/modules/footer/footer-upper-curve';
import SelectLanguage from '../components/modules/language-selector/in-drawer';
import NextLoading from '../components/modules/next-loading';
import PageWrapper from '../components/modules/page-wrapper';
import { AppContextProvider } from '../components/providers/app-context';
import GTM from '../components/providers/gtm';
import { useErrorKey } from '../hooks/use-error-reset-key';
import { settingsModalOpen } from '../state/cart';
// TODO: ADD AFTER NEXT FIX BUG ON ERROR PAGE
import '../styles/globals.css';

import { configPage, presetupConfigs } from '../utils/configPage';

const UserSettingsModal = dynamic(
  () => import('../components/modules/user-settings-modal')
);

const ShippingSelectorInDrawer = dynamic(
  () => import('../components/modules/shipping-selector/in-drawer')
);

const Footer = dynamic(() => import('../components/modules/footer'));

const Header = dynamic(() => import('../components/modules/header'));

const Alerts = dynamic(() => import('../components/modules/alerts'), {
  ssr: false,
});

const VERO = dynamic(() => import('../components/providers/vero'), {
  ssr: false,
});

const CookieConcent = dynamic(
  () => import('../components/modules/cookie-concent'),
  {
    ssr: false,
  }
);

const Cart = dynamic(() => import('../components/modules/cart'), {
  ssr: false,
});

const Menu = dynamic(() => import('../components/modules/menu'), {
  ssr: false,
});

const Search = dynamic(() => import('../components/modules/search'), {
  ssr: false,
});

const Error505 = dynamic(() => import('../pages/500'), {
  ssr: false,
});

const Usmodal = dynamic(() => import('../components/modules/us-modal'), {
  ssr: false,
});

const Ukmodal = dynamic(() => import('../components/modules/uk-modal'), {
  ssr: false,
});

const GlobalUserModal = dynamic(
  () => import('../components/modules/global-modal'),
  {
    ssr: false,
  }
);

type TMyAPP = AppProps & {
  Component: AppProps['Component'] & TPageConfig;
};

const MyApp = memo(({ Component, pageProps }: TMyAPP) => {
  const {
    locale = 'en',
    pathname,
    asPath,
    query: { vehicle, category },
  } = useRouter();
  const [isSettingModalOpen] = useAtom(settingsModalOpen);

  const componentSetup = configPage[pathname] || presetupConfigs.default;
  Component.header = componentSetup.header;
  Component.menu = componentSetup.menu;
  Component.wrapper = componentSetup.wrapper;
  Component.footerCurve = componentSetup.footerCurve;
  Component.footer = componentSetup.footer;

  if (Component.isError500) {
    return <Component />;
  }

  return (
    <>
      <GTM />
      <VERO />
      <CookieConcent />
      <Usmodal />
      <Ukmodal />
      <GlobalUserModal />

      {isSettingModalOpen && <UserSettingsModal />}
      <Header type={Component.header || 'DEFAULT'} />

      <Alerts />
      <Menu type={Component.menu || 'DEFAULT'} />

      <Cart />
      <Search />
      <ShippingSelectorInDrawer />
      <SelectLanguage />

      <div className={cx('relative', 'z-[1]')}>
        <NextLoading />
        <PageWrapper type={Component.wrapper}>
          <Component {...pageProps} />
        </PageWrapper>
      </div>

      <FooterUpperCurve variant={Component.footerCurve} />
      <Footer type={Component.footer || 'DEFAULT'} />
    </>
  );
});

MyApp.displayName = 'MyApp';

const ErrorProtection = (props) => {
  const key = useErrorKey();

  return (
    <ErrorBoundary fallback={<Error505 />} resetKeys={[key]}>
      <MyApp {...props} />
    </ErrorBoundary>
  );
};

const AppProviders = (props) => {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            retry: false,
          },
        },
      })
  );

  return (
    <Provider>
      <QueryClientProvider client={queryClient}>
        <AppContextProvider
          shippingLocation={props.pageProps.shippingLocation}
          detailPrices={props.pageProps.detailPrices}
        >
          <Hydrate state={props.pageProps.dehydratedState}>
            <ErrorProtection {...props} />
          </Hydrate>
        </AppContextProvider>
      </QueryClientProvider>
    </Provider>
  );
};

const __Page_Next_Translate__ = AppProviders;


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: true,
// @ts-ignore
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
    });
// @ts-ignore
  